<div class="top-nav">
  <div class="nav-logo">
    <a class="nav-brand">
      <div class="flex items-center">
        <img src="assets/maxim-logo/maxim-logo.webp" [width]="175" alt="maxim">
<!--        <p class="logo-text">MAXIM</p>-->
      </div>
    </a>
  </div>
  <div></div>
  <div></div>
</div>
