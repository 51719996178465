<div class="loading-container">
    <div class="dot-container">
        @for (dot of dots(); track dot) {
            <div class="dot"
                 [style.animation-delay]="dot.delay + 's'"
                 [style.background-color]="dot.color"
                 [style.animation-duration]="(_speed() + 's')">
            </div>
        }
    </div>
</div>

<!--<div class="dot-container">-->
<!--    <div class="dot"></div>-->
<!--    <div class="dot"></div>-->
<!--    <div class="dot"></div>-->
<!--</div>-->
