// loading-overlay.component.ts
import {Component, Input} from '@angular/core';
import {DotAnimationComponent} from "../dot-animation/dot-animation.component";

@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  imports: [DotAnimationComponent],
  template: `
    <div class="loading-overlay">
      <app-dot-animation></app-dot-animation>
    </div>
  `,
  styles: [`
    .loading-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
  `]
})
export class LoadingOverlayComponent {
  @Input() isLoading = false;
}