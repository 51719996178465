import {Component, computed, Input, signal} from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-dot-animation',
  standalone: true,
    imports: [
        NgForOf
    ],
  templateUrl: './dot-animation.component.html',
  styleUrl: './dot-animation.component.scss'
})
export class DotAnimationComponent {
  @Input() set dotColors(colors: string[]) {
    this._colors.set(colors);
  }
  @Input() set animationSpeed(speed: number) {
    this._speed.set(speed);
  }

  private _colors = signal(['#FF69B4', '#FFA9D6', '#FF1493']);
  protected _speed = signal(1.5);

  dots = computed(() =>
      this._colors().map((color, index) => ({
        color,
        delay: (index * this._speed()) / 3
      }))
  );
}