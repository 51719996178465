@if (dotLoadingServices.isLoading()) {
    <app-loading-overlay></app-loading-overlay>
}
@if (currentNotification(); as notification) {
    <app-notification-alert
            [type]="notification.type"
            [message]="notification.message">
    </app-notification-alert>
}
<div class="app-container" [class.login-page]="isLoginPage">
    @if (!isLoginPage) {
        <app-app-side-nav></app-app-side-nav>
    }
    <main class="main-content" role="main">
        <router-outlet></router-outlet>
    </main>
</div>



