import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthGuard} from "./core";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
    // canActivate:[LoginGuard]
  },
  {
    path: 'home',
    loadComponent: () => import('./components/index').then(com => com.HomeComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'mas-order-receive',
    loadComponent: () => import('./components/index').then(com => com.ReceiveOrderComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'sales-order',
    loadComponent: () => import('./components/index').then(com => com.SalesOrderComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'sales-order-item',
    loadComponent: () => import('./components/index').then(com => com.SalesOrderItemComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'packing-list',
    loadComponent: () => import('./components/index').then(com => com.PackingListComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'invoice-create',
    loadComponent: () => import('./components/index').then(com => com.InvoiceCreateComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'invoice-view',
    loadComponent: () => import('./components/index').then(com => com.InvoiceViewComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'reports',
    loadComponent: () => import('./components/index').then(com => com.ReportsComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'mas-order-delivery',
    loadComponent: () => import('./components/index').then(com => com.DeliveryConfirmationComponent),
    canActivate:[AuthGuard]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
