import {Component, computed, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {LoadingService} from './core/components/Loadings/loading/loading.service';
import {NotificationService} from './components/services/notification.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {DotLoadingServices} from "./core/services/dot-loading.Services";
import {initFlowbite} from "flowbite";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  isLoginPage: boolean = false;
  isOnline: boolean = navigator.onLine;
  currentNotification = computed(() => this.notificationService.notification());

  constructor(
      private loadingService: LoadingService,
      public dotLoadingServices: DotLoadingServices,
      public notificationService: NotificationService,
      private router: Router

  ) {}

  ngOnInit() {
    initFlowbite();
    this.loadingService.isLoading.subscribe((isLoading) => {
      this.loading = isLoading;
    });


    this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isLoginPage = event.urlAfterRedirects.includes('/login');
    });

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    // Initial check
    this.updateOnlineStatus();
  }


  ngOnDestroy() {
    // Remove event listeners when component is destroyed
    window.removeEventListener('online', this.updateOnlineStatus.bind(this));
    window.removeEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  private updateOnlineStatus() {
    const wasOnline = this.isOnline;
    this.isOnline = navigator.onLine;

    if (this.isOnline && !wasOnline) {
      this.notificationService.showNotification({
        type: 'success',
        message: 'Connected. Internet Connection Restored.'
      });
    } else if (!this.isOnline && wasOnline) {
      this.notificationService.showNotification({
        type: 'error',
        message: 'Internet connection lost'
      });
    }
  }
}