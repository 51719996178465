import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {LoadingComponent} from './core/components/Loadings/loading/loading.component'
import {AuthInterceptor} from './core/interceptors'
import {AppSideNavComponent, LoginComponent} from "./components"
import {TopNavComponent} from "./core/components/top-nav/top-nav.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {LoadingOverlayComponent} from "./core/components/Loadings/loading-overlay/loading-overlay.component";
import {NotificationAlertComponent} from "./components/shared/notification";

@NgModule({ declarations: [
        AppComponent,
        LoadingComponent,
    ],
    bootstrap: [AppComponent],
    exports: [
        NotificationAlertComponent,
    ], imports: [BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        AppSideNavComponent,
        TopNavComponent,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SweetAlert2Module.forRoot(),
        LoginComponent,
        LoadingOverlayComponent,
        NotificationAlertComponent,
        NotificationAlertComponent], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
